import React, { useState, useEffect, useMemo } from 'react';
import { Phone, DoctorInfo, UserInfo } from '@steps';
import { Layout, StepControls } from '@components';
import { motion } from 'framer-motion';
import {
  Box,
  Image,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
} from '@chakra-ui/react';
import {
  convertDatesToUnix,
  convertDatesInObject,
  currentDate,
  filterObject,
  hashString,
} from '@helpers';
import { identifyCustomer, getCustomerAttributes, generateSessionToken } from '@api';

const FormContainer = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [campaignData, setCampaignData] = useState({});
  const [experimentsData, setExperimentsData] = useState({});
  const [hasError, setHasError] = useState(false);
  const [platform, setPlatform] = useState('');

  const steps = useMemo(
    () => [
      {
        component: Phone,
        title: 'Phone',
        slug: 'phone',
      },
      {
        component: UserInfo,
        title: 'User Info',
        slug: 'user-info',
      },
      {
        component: DoctorInfo,
        title: 'Doctor Info',
        slug: 'doctor-info',
      },
    ],
    [],
  );

  const images = ['lifestyle5.jpg', 'lifestyle1.jpg', 'lifestyle7.jpg'];

  const ActiveStepComponent = steps[step - 1].component;

  const stepTitles = steps.map((step) => step.title);

  useEffect(() => {
    const sessionToken = formData.completed ? null : generateSessionToken();

    if (formData.phone_number) {
      const dataObject = convertDatesToUnix({ ...formData });

      // The app can sometimes send data that is malformed. To prevent this we filter out any keys that are not explicity set in the filterObject function.
      const sanitizedDataObject = filterObject({
        ...dataObject,
        ...campaignData,
        experiments: experimentsData,
        step: step === 1 ? 2 : step,
        sessionToken,
        platform,
      });

      const hashedPhoneNumber = hashString(formData.phone_number);

      // Send the data to Customer.io via their REST API
      identifyCustomer(hashedPhoneNumber, { ...sanitizedDataObject })
        .then((wasIdentified) => {
          if (wasIdentified) {
            setHasError(false);
          } else {
            setHasError(true);
            throw new Error(
              `There was a problem identifying the customer: ${formData.phone_number}`,
            );
          }
        })
        .catch(() => {
          setHasError(true);
          throw new Error(`There was a problem identifying the customer: ${formData.phone_number}`);
        });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'cio_identify',
        cio_id: hashedPhoneNumber,
        cio_created_at: currentDate,
        cio_data_object: { ...sanitizedDataObject },
      });
    }

    if (steps[step - 1]?.title) {
      window.dataLayer.push({
        event: 'page_view_inquiry_flow',
        page_title: `Inquiry Flow - ${steps[step - 1].title}`,
        page_location: `https://www.unityscreen.com/inquiry-flow/${steps[step - 1].slug}`,
      });
    }
  }, [formData, steps, step, campaignData, experimentsData, platform]);

  // Get the campaign data from the internal cookie
  useEffect(() => {
    if (window.CampaignTracker) {
      const campaignData = window.CampaignTracker().getCampaignData();
      setCampaignData(campaignData);
    }
  }, []);

  // Get the experiments data from the internal cookie
  useEffect(() => {
    if (window.ExperimentsTracker) {
      const experimentsData = window.ExperimentsTracker().getExperimentsDataFromCookie();
      setExperimentsData(experimentsData);
    }
  }, []);

  useEffect(() => {
    // Check the query params for a session token
    const urlParams = new URLSearchParams(window.location.search);
    const urlSessionToken = urlParams.get('sessionToken');

    // If the user has a session token query Customer.io for their data
    if (urlSessionToken) {
      // If the user has already filled out the form, pre-populate the form with their data
      getCustomerAttributes(urlSessionToken).then((customerData) => {
        if (customerData) {
          const formattedCustomerData = convertDatesInObject(customerData);
          setFormData({ ...formattedCustomerData });

          // Skip to the last step that the user was on
          if (formattedCustomerData.step) {
            setStep(parseInt(formattedCustomerData.step, 10));
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    const windowSource =
      window.location.host === 'inquiry.unityscreen.com' ? 'unity_patient' : 'development';
    setPlatform(windowSource);
  }, []);

  const goToNextStep = () => {
    if (step < steps.length) {
      setStep(step + 1);
    }
  };

  const goToStep = (stepNumber) => {
    if (stepNumber > 0 && stepNumber <= steps.length) {
      setStep(stepNumber);
    }
  };

  return (
    <Layout>
      {hasError && (
        <Alert
          status='error'
          position='absolute'
          top={0}
          left={0}
          right={0}
          zIndex={100}
          padding={10}
          backgroundColor='red'
          color='white'
          textAlign='center'
        >
          <AlertIcon />
          <AlertTitle mr={2}>There was a problem saving the data. Please try again.</AlertTitle>
          <AlertDescription
            as={Button}
            variant='link'
            onClick={() => goToStep(step - 1)}
          >
            Return to previous step
          </AlertDescription>
        </Alert>
      )}
      <Box
        flex='1'
        padding={{ base: 15, sm: 30, lg: 60 }}
        display='grid'
        gap={60}
        gridTemplateRows='auto 1fr'
      >
        <Box>
          <Image
            src='/images/logo.svg'
            alt='UNITY logo'
            width={{ base: '10rem', sm: 'auto' }}
            mb={10}
          />
          <StepControls
            step={step}
            steps={stepTitles}
            goToStep={goToStep}
          />
        </Box>
        <Box alignSelf={{ lg: 'center' }}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            key={step}
          >
            <ActiveStepComponent
              data={formData}
              setData={(data) => setFormData({ ...formData, ...data })}
              goToNextStep={() => goToNextStep()}
            />
          </motion.div>
        </Box>
      </Box>
      <Box
        flex='1'
        alignSelf='stretch'
        display={{ base: 'none', lg: 'block' }}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
          key={step}
          style={{
            height: '100%',
          }}
        >
          <Image
            height='100%'
            width='100%'
            objectFit='cover'
            objectPosition='top'
            src={`/images/${images[step - 1]}`}
            alt='An expectant mother looking at her phone'
          />
        </motion.div>
      </Box>
    </Layout>
  );
};

export default FormContainer;
