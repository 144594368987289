import React from 'react';
import { Field } from 'formik';
import { Text, Checkbox as ChakraCheckbox } from '@chakra-ui/react';

function Checkbox(props) {
  return (
    <Field name={props.name}>
      {({ field, form }) => (
        <ChakraCheckbox
          {...field}
          type='checkbox'
          isChecked={field.value}
          onChange={() => {
            form.setFieldValue(field.name, !field.value);
            form.setFieldTouched(field.name, true);
          }}
          iconColor='green'
          size='lg'
          lineHeight='1'
          spacing={10}
          inputProps={{
            background: 'white',
          }}
        >
          <Text
            as='span'
            fontSize='0.875rem'
            dangerouslySetInnerHTML={{ __html: props.label }}
          />
        </ChakraCheckbox>
      )}
    </Field>
  );
}

export default Checkbox;
